import React, {useEffect} from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import './App.scss';
import Home from "./pages/Home";
import OnePost from "./pages/OneProject";
import AboutPage from "./pages/about-page";
import BelgiquePage from "./pages/belgique-page";
import NotFound from "./pages/notfound";
import OneEvent from "./pages/OneEvent";

function App () {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return(
        <>
            <Router>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/:slug" element={<OnePost />} />
                    <Route path="/events/:slug" element={<OneEvent />} />
                    <Route path="/about" element={<AboutPage />} />
                    <Route path="/iteka-belgique" element={<BelgiquePage />} />
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </Router>
        </>
    );
}

export default App;
