import React, {useEffect, useState} from 'react';
import {client, urlFor} from "../client";
import {AppWrap, MotionWrap} from "../wrapper";

const Banner = () => {

    const [currentIndex, setCurrentIndex] = useState(0);
    const [sliders, setSliders] = useState([]);
    const [homebanners, setHomebanners] = useState([]);
    const [socios, setSocios] = useState([]);

    useEffect(() => {
        const querySliders = '*[_type == "sliders"]';
        const queryBanners = '*[_type == "homebanners"]';
        const querySocio = '*[_type == "socialmedia"]';

        client.fetch(querySliders).then((dataSliders) => {
            setSliders(dataSliders);
        });
        client.fetch(queryBanners).then((dataHomebanners) => {
            setHomebanners(dataHomebanners);
        });
        client.fetch(querySocio).then((dataSocio) => {
            setSocios(dataSocio);
        });
    }, []);

    const handleClick = (index) => {
        setCurrentIndex(index);
    };

    return (
        <section className="promo">
                    {sliders.length && (
                    <>
                        <div className="promo-slider">
                            <div className="promo-slider__item promo-slider__item--style-2" style={{width: '100%', display: 'inline-block'}}>
                                <picture>
                                    <img src={urlFor(sliders[currentIndex].imgUrl)} alt={sliders[currentIndex].name} className="img--bg"/>
                                </picture>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-xl-7">
                                            <div className="align-container">
                                                <div className="align-container__item">
                                                    <div className="promo-slider__wrapper-1">
                                                        <h2 className="promo-slider__title"><span>{sliders[currentIndex].title}</span></h2>
                                                    </div>
                                                    <div className="promo-slider__wrapper-2">
                                                        <p className="promo-slider__subtitle">{sliders[currentIndex].subtitle}</p>
                                                    </div>
                                                    <div className="promo-slider__wrapper-3">
                                                        <a className="button promo-slider__button button--primary" href={sliders[currentIndex].link} target="_blank" rel="noreferrer" >{sliders[currentIndex].linktext}</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="slider__nav slider__nav--promo">
                            <div className="promo-slider__count"/>
                            <div className="slider__arrows">
                                <div className="slider__prev" onClick={() => handleClick(currentIndex === 0 ? sliders.length - 1 : currentIndex - 1)}><i className="fa fa-chevron-left" aria-hidden="true"/></div>
                                <div className="slider__next" onClick={() => handleClick(currentIndex === sliders.length - 1 ? 0 : currentIndex + 1)}><i className="fa fa-chevron-right" aria-hidden="true"/></div>
                            </div>
                        </div>
                    </>
                    )}

                    <ul className="promo-socials">
                        {socios.map((social) => (
                            <li className="promo-socials__item" key={social.title}>
                                <a className="promo-socials__link" href={social.link}>{social.imgurl ? <img className="social-banner" src={urlFor(social.imgurl)} alt={social.title}/> : ''}</a>
                            </li>
                        ))}
                    </ul>


                    {homebanners.map((banner, index) => (
                    <div className="promo-pannel" key={banner.id + index}>
                        <a className="anchor promo-pannel__anchor" href="#about">
                            <span>Scroll Down</span>
                        </a>
                        <div className="promo-pannel__video">
                            <img className="img--bg" src={urlFor(banner.imgUrl)} alt={banner.title}/> : ''}
                            <a className="video-trigger" href={banner.videolink} target="_blank" rel="noreferrer" >
                                <span>Watch our last video</span>
                                <i className="fa fa-play" aria-hidden="true" />
                            </a>
                        </div>
                        <div className="promo-pannel__phones">
                            <p className="promo-pannel__title">Phone numbers</p>
                            <a className="promo-pannel__link" href="tel:+180012345678">{banner.phone}</a>
                        </div>
                        <div className="promo-pannel__email">
                            <p className="promo-pannel__title">Email</p>
                            <a className="promo-pannel__link" href="mailto:support@helpo.org">{banner.email}</a>
                        </div>
                    </div>
                        ))}

        </section>
    );
};

export default AppWrap(
    MotionWrap(Banner),
    'home',
);
