import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {client, urlFor} from "../client";
import Navbar from "../components/Navbar/Navbar";
import {PortableText} from "@portabletext/react";
import Footer from "../components/Footer/Footer";
import {motion} from "framer-motion";
import Aside from "../components/Navbar/Aside";

export default function OnePost() {
    const [projectData, setProjectData] = useState(null);
    const { slug } = useParams();

    useEffect(() => {

        client
            .fetch(
                `*[slug.current == "${slug}"]`
            )
            .then((data) => setProjectData(data[0]))
            .catch(console.error);
    }, [slug]);

    if (!projectData) return <div>Loading...</div>;

    return (
        <>
            <div className="page-wrapper">
                <Aside />
                <Navbar />
                <main className="main">
                    <section className="promo-primary">
                        <picture>
                            {projectData.imgUrl ? <img src={urlFor(projectData.imgUrl)} alt={projectData.title} className="img--bg"/> : ''}
                        </picture>
                        <div className="container">
                            <div className="row">
                                <div className="col-auto">
                                    <div className="align-container">
                                        <motion.div
                                            whileInView={{ y: [100, 50, 0], opacity: [0, 0, 1] }}
                                            transition={{ duration: 0.8 }}
                                            className="align-container__item"
                                        >
                                            <span className="promo-primary__pre-title">{projectData.tags}</span>
                                            <h1 className="promo-primary__title"><span>{projectData.title}</span></h1>
                                        </motion.div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="section">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="tabs horizontal-tabs cause-details-tabs">
                                        <div className="horizontal-tabs__content">
                                            <div className="horizontal-tabs__item" id="horizontal-tabs__item-1">
                                                <motion.div
                                                    whileInView={{ y: [100, 50, 0], opacity: [0, 0, 1] }}
                                                    transition={{ duration: 0.5 }}
                                                >
                                                <PortableText value={projectData.description}/>
                                                </motion.div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <Footer />
                </main>
            </div>
        </>
    );
}

