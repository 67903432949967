import sanityClient from '@sanity/client';
import imageUrlBuilder from '@sanity/image-url';

export const client = sanityClient({
    projectId: '3uuurioy',
    dataset: 'production',
    apiVersion: '2022-02-01',
    useCdn: true,
    token: 'skauRmjWdVLjsT7p5fENWvIMWEPBQgIKIgVIaNjewCRkVHbnaY93smHl37kdnWIa45BtqL3FHzkzHoVufef6O2y9S2dfoNVQLjJGkCJSP1bkpyCYolsn46CmCmexKko0XuZhhJWW203GOeYYs65AZO0j8rq8rQesCfeol2Qu1A5eg50CY8v0',
});

const builder = imageUrlBuilder(client);

export const urlFor = (source) => builder.image(source);
