import React from 'react';
import { images } from '../../constants';
import {Link} from "react-router-dom";

const Footer = () => {

    return (
        <>
            <footer className="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-6 col-lg-3">
                            <div className="footer-logo"><a className="footer-logo__link" href="#"><img
                                className="footer-logo__img" src={images.logo} alt="logo"/></a></div>
                            <ul className="footer-socials">
                                <li className="footer-socials__item"><a className="footer-socials__link" href="#"><i className="fa fa-facebook" aria-hidden="true" /></a></li>
                                <li className="footer-socials__item"><a className="footer-socials__link" href="#"><i className="fa fa-twitter" aria-hidden="true" /></a></li>
                                <li className="footer-socials__item"><a className="footer-socials__link" href="#"><i className="fa fa-google-plus" aria-hidden="true" /></a></li>
                                <li className="footer-socials__item"><a className="footer-socials__link" href="#"><i className="fa fa-instagram" aria-hidden="true" /></a></li>
                            </ul>
                        </div>
                        <div className="col-sm-6 col-lg-3">
                            <h4 className="footer__title">Contacts</h4>
                            <div className="footer-contacts">
                                <p className="footer-contacts__address">Nyarugunga, KK 5 Av 64St, Kigali, Rwanda</p>
                                <p className="footer-contacts__phone">Phone: <a href="tel:+250 789429057 ">+250 789429057 </a></p>
                                <p className="footer-contacts__mail">Email: <a href="mailto:contact@itekarwanda.org">contact@itekarwanda.org</a></p>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-3">
                            <h4 className="footer__title">Links</h4>
                            <nav>
                                <ul className="footer-menu">
                                    <li className="footer-menu__item"><Link to="/about" className="footer-menu__link">About us</Link></li>
                                </ul>
                            </nav>
                        </div>
                        <div className="col-sm-6 col-lg-3">
                            <h4 className="footer__title">Donate</h4>
                            <p>Help Us Change the Lives of Children in World</p>
                            <a className="button footer__button button--filled" href="#contact">Donate</a>
                        </div>
                    </div>
                    <div className="row align-items-baseline">
                        <div className="col-md-6">
                            <p className="footer-copyright">© {new Date().getFullYear()} Iteka Rwanda</p>
                        </div>
                        <div className="col-md-6">
                            <div className="footer-privacy">
                               {/* <a className="footer-privacy__link" href="#">Privacy Policy</a>
                                <span className="footer-privacy__divider">|</span>
                                <a className="footer-privacy__link" href="#">Term and Condision</a>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
};

export default Footer;
