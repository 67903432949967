import React, {useState, useRef} from 'react';
import emailjs from 'emailjs-com';

import { images } from '../constants';
import {AppWrap, MotionWrap} from "../wrapper";

const Contact = () => {

    const [formData, setFormData] = useState({ firstname: '', lastname: '', phoneNumber: '', email: '', message: '' });
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);

    const { firstname, lastname, email, message, phoneNumber } = formData;

    const handleChangeInput = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const form = useRef();

    const sendEmail = (e) => {
        setLoading(true);
        e.preventDefault();

        emailjs.sendForm('service_0tzkuoj', 'template_iteka', form.current, 'POd5fikQLRmgVGsuG')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
        e.target.reset();
        setLoading(false);
        setIsFormSubmitted(true);
    };

    return (
        <>
            <section className="section contacts">
                <div className="container">
                    <div className="row offset-margin">
                        <div className="col-sm-6 col-lg-3">
                            <div className="icon-item">
                                <div className="icon-item__img">
                                    <img className="img--layout" src={images.location} alt="img"/>
                                    <svg className="icon icon-item__icon icon--red">
                                    </svg>
                                </div>
                                <div className="icon-item__text">
                                    <p>Address:<br/>Nyarugunga, KK 5 Av 64st, Kigali, Rwanda</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-3">
                            <div className="icon-item">
                                <div className="icon-item__img">
                                    <img className="img--layout" src={images.telephone} alt="img"/>
                                    <svg className="icon icon-item__icon icon--orange">
                                    </svg>
                                </div>
                                <div className="icon-item__text">
                                    <p>Phone:<br/>
                                        <a className="icon-item__link" href="tel:+250 789429057">+250 789429057 </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-3">
                            <div className="icon-item">
                                <div className="icon-item__img">
                                    <img className="img--layout" src={images.email} alt="img"/>
                                    <svg className="icon icon-item__icon icon--green">
                                    </svg>
                                </div>
                                <div className="icon-item__text">
                                    <p>Email: <a className="icon-item__link" href="mailto:contact@itekarwanda.org">contact@itekarwanda.org</a></p>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-3">
                            <div className="icon-item">
                                <div className="icon-item__img">
                                    <img className="img--layout" src={images.social} alt="img"/>
                                    <svg className="icon icon-item__icon icon--blue">
                                    </svg>
                                </div>
                                <div className="icon-item__text">
                                    <p>Social medias:<br/></p>
                                    <div className="socialdiv">
                                        <a className="socials__link" href="https://www.instagram.com/itekayouthorganization" target="_blank" rel="noreferrer"><i className="fa fa-instagram" aria-hidden="true" /></a>
                                        <a className="socials__link" href="https://www.facebook.com/itekayouthorganization" target="_blank" rel="noreferrer"><i className="fa fa-facebook" aria-hidden="true" /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section contacts no-padding-top">
                <div className="contacts-wrapper">
                    <div className="container">
                        <div className="row justify-content-end">
                            <div className="col-xl-12">
                                {!isFormSubmitted ? (
                                <form className="form message-form" ref={form} onSubmit={sendEmail}>
                                    <h6 className="form__title">Send Message</h6>
                                    <span className="form__text">* The following info is required</span>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <input className="form__field"
                                                   type="text"
                                                   name="firstname"
                                                   placeholder="First Name *"
                                                   value={firstname}
                                                   required={true}
                                                   onChange={handleChangeInput}
                                            />
                                        </div>
                                        <div className="col-lg-6">
                                            <input className="form__field"
                                                   type="text"
                                                   name="lastname"
                                                   placeholder="Last Name *"
                                                   value={lastname}
                                                   required={true}
                                                   onChange={handleChangeInput}
                                            />
                                        </div>
                                        <div className="col-lg-6">
                                            <input className="form__field"
                                                   type="email"
                                                   name="email"
                                                   placeholder="Email *"
                                                   value={email}
                                                   required={true}
                                                   onChange={handleChangeInput}
                                            />
                                        </div>
                                        <div className="col-lg-6">
                                            <input className="form__field"
                                                   type="tel"
                                                   name="phoneNumber"
                                                   placeholder="Phone"
                                                   value={phoneNumber}
                                                   required={true}
                                                   onChange={handleChangeInput}
                                            />
                                        </div>
                                        <div className="col-12">
                                            <textarea className="form__message form__field"
                                                      name="message"
                                                      placeholder="Message"
                                                      value={message}
                                                      required={true}
                                                      onChange={handleChangeInput}
                                            />
                                        </div>
                                        <div className="col-12">
                                            <button className="form__submit" type="submit">{!loading ? 'Envoyé' : 'En cours d\'envoi...'}</button>
                                        </div>
                                    </div>
                                </form>
                                ) : (
                                    <div>
                                        <h3 className="head-text">
                                            Message transmis !
                                        </h3>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default AppWrap(
    MotionWrap(Contact),
    'contact',
);
