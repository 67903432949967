import React from 'react';
import {Helmet} from "react-helmet";
function Seo({ pageTitle, pageDescription }){
    const removeBr = (html) => {
        return html.replace(/<br \/>/g, ' ');
    };
    return (
        <>
          <Helmet>
              <title>{pageTitle &&  removeBr(pageTitle)}</title>
              <meta name="description" content={pageDescription} />
              <meta name="keywords" content="humanitarian, education, culture, NGO, rwanda, Burundi, Belgium" />
              <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
          </Helmet>
      </>
    );
}

export default Seo;
