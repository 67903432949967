import React from 'react'
import { images } from '../constants';
import Home from "./Home";
import {Link} from "react-router-dom";

const NotFound = () => {

    return (
        <>
            <main className="main">
                <img className="img--bg" src={images.error} alt="img"/>
                <section className="section error">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 col-xl-6">
                                <div className="align-container">
                                    <div className="align-container__item">
                                        <h1 className="error__title">404</h1>
                                        <h3 className="error__subtitle">Uh-Oh!</h3>
                                        <p className="error__text">We can't find the page you're looking for. Please use search or try starting from</p>
                                        <Link className="button button--primary" to={Home}>Home Page</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    );
};

export default NotFound;
