import React from 'react';
import {Navbar} from "../components";
import {About, Banner, Contact, Values, Events} from "../container";
import Projects from "../container/projects";
import Footer from "../components/Footer/Footer";
import Aside from "../components/Navbar/Aside";
import Seo from "../constants/Seo";

const Home = () => {
    return (
        <>
            <Seo pageTitle="humanitarian NGO which acts in rwanda" pageDescription="Non-governmental Organisation"/>
            <div className="page-wrapper">
                <Aside />
                <Navbar />
                <main className="main">
                    <Banner />
                    <About />
                    <Values />
                    <Events />
                    <Projects />
                </main>
                <Contact />
                <Footer />
            </div>
        </>
    );
};

export default Home;
