import React, {useEffect, useState} from 'react';
import {client, urlFor} from "../client";
import { Link } from "react-router-dom";
import {AppWrap, MotionWrap} from "../wrapper";

const Projects = () => {
    const [projects, setProjects] = useState([]);

    useEffect(() => {
        const query = '*[_type == "projects"]';

        client.fetch(query).then((data) => {
            setProjects(data);
        });
    }, []);

    return (
        <section className="section">
            <>
                <div className="container">
                    <div className="row no-gutters">
                        <div className="col-lg-6 col-xl-8 projects-masonry__item projects-masonry__item--height-2 projects-masonry__item--primary projects-masonry__item--heading">
                            <div className="projects-masonry__img">
                                <div className="heading heading--primary">
                                    <span className="heading__pre-title">What we're doing</span>
                                    <h2 className="heading__title"><span>Iteka</span> <span>Projects</span></h2>
                                    <p>A lot of work is planned, Let’s check some</p>
                                </div>
                            </div>
                        </div>
                        {projects.map((project, index) => (
                                <div className="col-lg-6 col-xl-4 projects-masonry__item projects-masonry__item--height-2 projects-masonry__item--primary" key={project._id}>
                                    <div className="projects-masonry__img">
                                        {project.imgUrl ? <img className="img--bg" src={urlFor(project.imgUrl)} alt={project.title}/> : ''}
                                        <div className="projects-masonry__inner">
                                            {project.tags?.map((tag) => (
                                                <span className="projects-masonry__badge" key={tag}>{tag} </span>
                                            ))}
                                            <h3 className="projects-masonry__title">
                                                <Link to={"/" + project.slug.current} key={project.slug.current}>
                                                {project.title}
                                                </Link>
                                            </h3>
                                            {/*<PortableText value={project.excerpt}/>*/}
                                        </div>
                                    </div>
                                </div>
                        ))}
                    </div>
                </div>
            </>
        </section>
    );
};

export default AppWrap(
    MotionWrap(Projects),
    'projects',
);
