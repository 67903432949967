import React from 'react';
import {images} from "../../constants";
import {Link} from "react-router-dom";

const Aside = ({ isActive, onClose }) => {

    const handleClick = () => {
        onClose();
    };

    return (
        <>
            <div className={`aside-dropdown ${isActive ? 'aside-dropdown--active' : ''}`}>
                <div className="aside-dropdown__inner">
                    <img className="aside-dropdown__close" src={images.close} alt="close" onClick={onClose} />
                    <div className="aside-dropdown__item d-lg-none d-block">
                        <ul className="aside-menu">
                            {['home', 'about', 'values', 'projects', 'contact'].map((item) => (
                                <li className="aside-menu__item" key={`link-${item}`}>
                                    <a
                                        className="aside-menu__link"
                                        href={`#${item}`}
                                        onClick={handleClick}
                                    >
                                        <span>{item}</span>
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="aside-dropdown__item">
                        <Link className="button button--squared belgique" to="iteka-belgique" onClick={handleClick} >
                            <span>Iteka Belgique</span>
                        </Link>
                        <Link className="button button--squared" to="#contact" onClick={handleClick} >
                            <span>Donate</span>
                        </Link>
                    </div>
                    <div className="aside-dropdown__item">
                        <div className="aside-inner"><span className="aside-inner__title">Email</span><a
                            className="aside-inner__link" href="mailto:support@helpo.org">contact@itekarwanda.org</a></div>
                        <div className="aside-inner"><span className="aside-inner__title">Phone numbers</span><a
                            className="aside-inner__link" href="tel:+250 789429057">+250 789429057</a></div>
                        <ul className="aside-socials">
                            <li className="aside-socials__item">
                                <Link className="aside-socials__link" to="https://www.instagram.com/itekayouthorganization" target="_blank" rel="noreferrer">
                                    <i className="fa fa-instagram" aria-hidden="true" />
                                </Link>
                            </li>
                            <li className="aside-socials__item">
                                <Link className="aside-socials__link" to="https://www.facebook.com/itekayouthorganization" target="_blank" rel="noreferrer">
                                    <i className="fa fa-facebook" aria-hidden="true" />
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Aside;
