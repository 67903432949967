import React, {useEffect, useState} from 'react';
import {client, urlFor} from "../client";

import {PortableText} from '@portabletext/react'
import Footer from "../components/Footer/Footer";
import Aside from "../components/Navbar/Aside";
import Seo from "../constants/Seo";
import Navbar from "../components/Navbar/Navbar";

const BelgiquePage = () => {

    const [abouts, setAbouts] = useState([]);

    useEffect(() => {
        const query = '*[_type == "belgiquepage"]';

        client.fetch(query).then((data) => {
            setAbouts(data);
        });
    }, []);

    return (
        <>
            <Seo pageTitle="humanitarian NGO which acts in rwanda" pageDescription="humanitarian NGO which acts in rwanda"/>
            <div className="page-wrapper">
                <Aside />
                <Navbar />
                <main className="main">
                    {abouts.map((about) => (
                        <div key={about.slug}>
                            <section className="section about-us" id="about">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-6 col-xl-5">
                                            <div className="img-box">
                                                {/*<img className="img--layout" src="img/about_layout-reverse.png" alt="img"/>*/}
                                                {about.imgUrl ? <img src={urlFor(about.imgUrl)} alt={about.heading} className="img-box__img" style={{maxWidth: '470px'}}/> : ''}
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-xl-6 offset-xl-1">
                                            <div className="heading heading--primary"><span
                                                className="heading__pre-title">{about.heading}</span>
                                                <h2 className="heading__title"><span>{about.title}</span></h2>
                                            </div>
                                            <PortableText value={about.description}/>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    ))}
                </main>
                <Footer />
            </div>
        </>
    );
};

export default BelgiquePage;
