import React, {useState} from 'react';
import { images } from '../../constants';
import Aside from "./Aside";
import {Link} from "react-router-dom";

const NavbarProject = () => {

    const [color, setColor] = useState(false)
    const [isAsideActive, setIsAsideActive] = useState(false);

    const changeColor = () => {
        if (window.scrollY >= 90){
            setColor(true)
        } else {
            setColor(false)
        }
    }
    window.addEventListener('scroll', changeColor)

    const toggleAside = () => {
        setIsAsideActive(!isAsideActive);
    };

    return (
        <>
            <Aside />
            <header className={color ? 'header header--front header--fixed' : 'header header--front'}>
                <div className="container-fluid">
                    <div className="row no-gutters justify-content-between">
                        <div className="col-auto d-flex align-items-center">
                            <div className="dropdown-trigger d-none d-sm-block" onClick={toggleAside}>
                                <div className="dropdown-trigger__item"/>
                            </div>
                            <div className="header-logo">
                                <a className="header-logo__link" href="/">
                                    <img className="header-logo__img" src={images.logo} alt="logo"/>
                                </a>
                            </div>
                        </div>
                        <div className="col-auto">

                        </div>
                        <div className="col-auto d-flex align-items-center">
                            <div className="dropdown-trigger d-block d-sm-none">
                                <div className="dropdown-trigger__item" />
                            </div>
                            <Link className="button button--squared" to=""><span>Donate</span></Link>
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
};

export default NavbarProject;
