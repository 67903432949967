import volunteer from '../assets/volunteer.jpg';
import logo from '../assets/logo.png';
import location from '../assets/location.png';
import email from '../assets/email.png';
import social from '../assets/social-media.png';
import telephone from '../assets/telephone.png';
import close from '../assets/close.png';
import error from '../assets/404.jpg';
import visuel from '../assets/visuel.png';
import bg from '../assets/events_bg.png';
import bgevents from '../assets/events_inner-bg.png';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    volunteer,
    logo,
    location,
    email,
    social,
    telephone,
    close,
    error,
    visuel,
    bg,
    bgevents
};
