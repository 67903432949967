import React, {useEffect, useState} from 'react';
import { images } from '../../constants';
import {Aside} from "../index";
import {Link} from "react-router-dom";

const Navbar = () => {
    const [color, setColor] = useState(false)
    const [isAsideActive, setIsAsideActive] = useState(false);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    const changeColor = () => {
        if (window.scrollY >= 90){
            setColor(true)
        } else {
            setColor(false)
        }
    }
    window.addEventListener('scroll', changeColor)

    useEffect(() => {
        const changeWidth = () => {
            setScreenWidth(window.innerWidth);
        }

        window.addEventListener('resize', changeWidth)

        return () => {
            window.removeEventListener('resize', changeWidth)
        }
    })

    const toggleAside = () => {
        setIsAsideActive(!isAsideActive);
    };

    return (
        <>
            <Aside />
            <header className={color ? 'header header--front header--fixed' : 'header header--front'}>
                <div className="container-fluid">
                    <div className="row no-gutters justify-content-between">
                        <div className="col-auto d-flex align-items-center">
                            <div className="dropdown-trigger d-none d-sm-block">
                                <div className="dropdown-trigger__item" />
                            </div>
                            <div className="header-logo">
                                <Link to="#home" className="header-logo__link">
                                    <img className="header-logo__img" src={images.logo} alt="logo"/>
                                </Link>
                            </div>
                        </div>
                        <div className="col-auto">
                            <nav>
                                <ul className="main-menu">
                                    {['home', 'about', 'projects', 'events', 'contact'].map((item) => (
                                        <li className="main-menu__item main-menu__item--has-child" key={`link-${item}`}>
                                            <a className="main-menu__link" href={`#${item}`}><span>{item}</span></a>
                                        </li>
                                    ))}
                                </ul>
                            </nav>
                        </div>
                        <div className="col-auto d-flex align-items-center">
                            <div className="dropdown-trigger d-block d-sm-none" onClick={toggleAside}>
                                <div className="dropdown-trigger__item" />
                            </div>
                            <Link to="/iteka-belgique" className="button button--squared belgique">
                                <span>Iteka Belgique</span>
                            </Link>
                            <Link to="#contact" className="button button--squared">
                                <span>Donate</span>
                            </Link>
                        </div>
                    </div>
                </div>
            </header>
            <Aside isActive={isAsideActive} onClose={toggleAside} />
        </>
    );
};

export default Navbar;
