import React, {useEffect, useState} from 'react';
import {client, urlFor} from "../client";
import {AppWrap, MotionWrap} from "../wrapper";

const Values = () => {

    const [values, setValues] = useState([]);

    useEffect(() => {
        const query = '*[_type == "values"]';

        client.fetch(query).then((data) => {
            setValues(data);
        });
    }, []);

    return (
        <div>
            <section className="section icons-section background--brown">
                <div className="container">
                    <div className="row margin-bottom">
                        <div className="col-12">
                            <div className="heading heading--center">
                                <span className="heading__pre-title">What We do</span>
                                <h2 className="heading__title">
                                    <span>Core values</span>
                                </h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {values.map((value) => (
                        <div className="col-6 col-md-4 col-lg-3" key={value.name}>
                            <div className="icon-item">
                                <div className="icon-item__img">
                                    {value.imgurl ? <img src={urlFor(value.imgurl)} alt={value.name}/> : ''}
                                </div>
                                <div className="icon-item__text">
                                    <p>{value.name}</p>
                                </div>
                            </div>
                        </div>
                        ))}
                    </div>
                </div>
            </section>
        </div>
    );
};

export default AppWrap(
    MotionWrap(Values),
    'values',
);
