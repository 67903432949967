import React, { useEffect, useState } from "react";
import {useParams} from "react-router-dom";
import {client, urlFor} from "../client";
import {NavbarProject} from "../components";
import {PortableText} from "@portabletext/react";
import Footer from "../components/Footer/Footer";
import {motion} from "framer-motion";
import {images} from "../constants";
import Aside from "../components/Navbar/Aside";

export default function OneEvent() {
    const [eventData, setEventData] = useState(null);
    const [reports, setReports] = useState([]);
    const { slug } = useParams();

    useEffect(() => {
        const queryReports = '*[_type == "reports"]';

        client.fetch(queryReports).then((dataReports) => {
            setReports(dataReports);
            console.log(dataReports);
        });

        client
            .fetch(
                `*[slug.current == "${slug}"]`
            )
            .then((data) => setEventData(data[0]))
            .catch(console.error);
    }, [slug]);

    if (!eventData) return <div>Loading...</div>;

    return (
        <>
            <div className="page-wrapper">
                <Aside />
                <NavbarProject />
                <main className="main">
                    <section className="promo-primary">
                        <picture>
                            {eventData.imgUrl ? <img src={urlFor(eventData.imgUrl)} alt={eventData.title} className="img--bg"/> : ''}
                        </picture>
                        <div className="container">
                            <div className="row">
                                <div className="col-auto">
                                    <div className="align-container">
                                        <motion.div
                                            whileInView={{ y: [100, 50, 0], opacity: [0, 0, 1] }}
                                            transition={{ duration: 0.8 }}
                                            className="align-container__item"
                                        >
                                            <span className="promo-primary__pre-title">{eventData.tags}</span>
                                            <h1 className="promo-primary__title"><span>{eventData.title}</span></h1>
                                        </motion.div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="section pricing pricing-style--1">
                        <div className="container">
                            <div className="row offset-margin">
                                <div className="col-lg-4">
                                    <div className="heading heading--primary">
                                        <span className="heading__pre-title">For more details</span>
                                        <h2 className="heading__title">
                                            <span>Reports</span>
                                            {/*<span>Plan</span>*/}
                                        </h2>
                                        {/*<p>Sharksucker sea toad candiru rocket danio tilefish stingray deepwater
                                            stingray Sacramento splittail, Canthigaster rostrata.</p>*/}
                                    </div>
                                </div>
                                {reports.map((report, index) => (
                                <div className="col-sm-8 offset-sm-2 col-md-6 offset-md-0 col-lg-4" key={index}>
                                    <div className="pricing-item pricing-item--primary">
                                        <h6 className="pricing-item__plan">{report.title}</h6>
                                        {/*<div className="pricing-item__price"></div>*/}
                                        <a href={report.link} target="_blank" rel="noreferrer" className="pricing-item__button button button--primary">See</a>
                                    </div>
                                </div>
                                ))}
                            </div>
                        </div>
                    </section>
                    <section className="section storie-details">
                        <img className="article__bg" src={images.bgevents} alt="storie-details__bg" />
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-8 offset-xl-2">
                                    {/*<div className="storie-details__top">
                                        <div className="storie-details__img">
                                            {eventData.imgUrl ? <img src={urlFor(eventData.imgUrl)} alt={eventData.title} className="img--bg"/> : ''}
                                        </div>
                                        <div className="storie-details__description">
                                            <div className="row align-items-center">
                                                <div className="text-center text-md-left">
                                                    <h5 className="storie-details__name">{eventData.title}</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>*/}
                                    <motion.div
                                        whileInView={{ y: [100, 50, 0], opacity: [0, 0, 1] }}
                                        transition={{ duration: 0.5 }}
                                    >
                                        <PortableText value={eventData.description}/>
                                    </motion.div>
                                    <div className="article-information">
                                        <div className="row offset-30">
                                            {eventData.details ?
                                                <div className="col-sm-6 col-lg-4">
                                                    <div className="article-information__item" style={{background: "#32C876"}}>
                                                        <h6 className="article-information__title">Details</h6>
                                                        <div className="article-information__details">
                                                            <div className="article-information__details-item">
                                                                <span><PortableText value={eventData.details}/></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                : ''}
                                            {eventData.organizer ?
                                                <div className="col-sm-6 col-lg-4">
                                                    <div className="article-information__item" style={{background: "#F76588"}}>
                                                        <h6 className="article-information__title">Organizer</h6>
                                                        <div className="article-information__details">
                                                            <div className="article-information__details-item">
                                                                <span><PortableText value={eventData.organizer}/></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                : ''}
                                            {eventData.venue ?
                                                <div className="col-sm-6 col-lg-4">
                                                    <div className="article-information__item" style={{background: "#49C2DF"}}>
                                                        <h6 className="article-information__title">Venue</h6>
                                                        <div className="article-information__details">
                                                            <div className="article-information__details-item">
                                                                <span><PortableText value={eventData.venue}/></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                : ''}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <Footer />
                </main>
            </div>
        </>
    );
}

