import React, {useEffect, useState} from 'react';
import {client, urlFor} from "../client";

import {PortableText} from '@portabletext/react'
import {AppWrap, MotionWrap} from "../wrapper";
import {Link} from "react-router-dom";

const About = () => {

    const [abouts, setAbouts] = useState([]);

    useEffect(() => {
        const query = '*[_type == "about"]';

        client.fetch(query).then((data) => {
            setAbouts(data);
        });
    }, []);

    return (
        <section className="section about-us" id="about">
            <div className="container">
                {abouts.map((about) => (
                <div className="row align-items-center" key={about.title}>
                    <div className="col-lg-6">
                        <div className="heading heading--primary">
                            <span className="heading__pre-title">About Us</span>
                            <h2 className="heading__title"><span>{about.title}</span></h2>
                        </div>
                        <PortableText value={about.description}/>
                        {about.link ? <Link className="button button--primary" to={about.link}>{about.linktitle}</Link> : ''}
                    </div>
                    <div className="col-lg-6 col-xl-5 offset-xl-1">
                        <div className="info-box">
                            {about.imgurl ? <img src={urlFor(about.imgurl)} alt={about.title} className="img--bg--about"/> : ''}
                        </div>
                    </div>
                </div>
                ))}
            </div>
        </section>
    );
};

export default AppWrap(
    MotionWrap(About),
    'about',
);
